import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Image, TrailguidePlugin } from "../../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Above the Garda lake and hidden in the Brenta Dolomites, there are the Bear
Trails around Molveno and Andalo. High above Trento, the idyllic Molveno lake
lies quiet infront of the rough Dolomite Rock walls.`}</p>
    <br />
    <p>{`Out of the village, the Pradel lift brings us up to nice warm up runs like
the Big Hero Flowtrail or the fast Blade runner trail, that allows you to
traverse to Andalo. Andalo is the center of wintersport and really quiet in
summertime. From here the lifts bring us up to the 2125m high Paganella.`}</p>
    <br />
    <p>{`An incredible view opens up, the Trento valley infront, the Brenta mountains
in the back and to the right we see how close we are to the Garda lake.`}</p>
    <br />
    <p>{`Looking at the map we see that we have many possibilities and can go to all
directions. Even descents of 1800vm down to Mezzolombardo or Sarche.`}</p>
    <TrailguidePlugin content="lat=46.1580&lng=11.0323&coverage=10" mdxType="TrailguidePlugin" />
    <p className="text-xs mb-16" align="center">
      <p>{`Open these trails directly in the `}<a parentName="p" {...{
          "href": "https://trailguide.net/?pos=12,46.1580,11.0323"
        }}>{`web-app`}</a></p>
    </p>
    <p>{`The terrain is versatile. No trees at the top, the trails are more alpine
style with many rocks. As we come down into the bushes and then to the forest,
the trails have more roots. The typical limestone rocks are everywhere and
offer zero grip when they are wet. Making the descents on the challenging
trails an interesting rodeo ride.`}</p>
    <br />
    <p>{`The Bear Trails is a network of mostly natural singletracks, but there are
also a few Flowtrails like the new Willi Wonka. Beside that, there is also a
classical bike park above Fai.`}</p>
    <br />
    <p>{`All in all a very versatile spot in a stunning landscape with challenging
but awesome trails and good infrastructure.`}</p>
    <br />
    <br />
    <h4>{`From top to lake`}</h4>
    <p>{`From the top of Paganella you can see all the way to the Garda Lake.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/2964"
      }}>{`here`}</a>{`.`}</p>
    <Image src="destinations/paganella_3.jpg" mdxType="Image" />
    <br />
    <p>{`On 2125masl you start in alpine terrain.`}</p>
    <Image src="destinations/paganella_4.jpg" mdxType="Image" />
    <br />
    <h4>{`Big Hero`}</h4>
    <p>{`On the other side of the valley, above Molveno, you find flowy trails.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/3007"
      }}>{`here`}</a>{`.`}</p>
    <Image src="destinations/paganella_1.jpg" mdxType="Image" />
    <br />
    <h4>{`Secret Trail`}</h4>
    <p>{`Most of the trails are natural style singletracks.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/3006"
      }}>{`here`}</a>{`.`}</p>
    <Image src="destinations/paganella_7.jpg" mdxType="Image" />
    <br />
    <h4>{`Ude's Trail`}</h4>
    <p>{`The enduro style trails are accessible with a good combination of lift
support and short uphills.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/2946"
      }}>{`here`}</a>{`.`}</p>
    <Image src="destinations/paganella_8.jpg" mdxType="Image" />
    <br />
    <h4>{`Big Hero`}</h4>
    <p>{`The Bear Trails are more for advanced riders. Nevertheless above Molveno
and with the Willi Wonka trail, Dolomiti Paganella built some easy trails also.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/3008"
      }}>{`here`}</a>{`.`}</p>
    <Image src="destinations/paganella_2.jpg" mdxType="Image" />
    <br />
    <h4>{`Peter Pan`}</h4>
    <p>{`On the north side, down to Fai, there is also a bike park with flow,
but also downhill tracks.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/2967"
      }}>{`here`}</a>{`.`}</p>
    <Image src="destinations/paganella_6.jpg" mdxType="Image" />
    <br />
    <h4>{`The Event Area of the Enduro Race Dolomiti Paganella - Picture by Manfred Stromberg.`}</h4>
    <p>{`The Enduro Race Dolomiti Paganella took place in Andalo. In two days and 9
stages, the riders enjoyed over 5000vm of descent.`}</p>
    <Image src="destinations/paganella_9.jpg" mdxType="Image" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      